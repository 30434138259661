<script lang="ts" setup>

  // Idioma
	const { locale, t } = useI18n()

  // Config
  const runtimeConfig = useRuntimeConfig()
  
  // Es mobile
  const isMobile = useState('isMobile', () => true)
  onMounted(() => {
    isMobile.value = window.innerWidth <= 768
    window.addEventListener('resize', () => {
      isMobile.value = window.innerWidth <= 768
    })
  })

  useHead({
    link: [
      {
        rel: 'icon',
        href: '/favicon.ico'
      }
    ],
    meta: [
      {
        name: 'viewport',
        content: 'width=device-width, initial-scale=1, maximum-scale=1'
      }
    ]
  })

</script>

<template>
  <NuxtLoadingIndicator color="repeating-linear-gradient(to right,#ff620a 0%,#C40058 100%)" />
	<div class="dark:bg-gray-900 flex flex-col justify-center items-center">
		<Header />
		<slot />
		<Footer />
	</div>
</template>
